
import { Component, Prop, Vue } from 'vue-property-decorator';
import { isSelectOptions, SelectOption } from '@/components/form/form.types';

@Component({
  inheritAttrs: false,
})
export default class GSelect extends Vue {
  @Prop(String) readonly id!: string;
  @Prop(String) readonly name!: string;
  @Prop([String, Number]) readonly value!: string | number;
  @Prop(String) readonly label!: string;
  @Prop(String) readonly placeholder!: string;
  @Prop(Array) readonly options!: SelectOption[] | string[];
  @Prop(Boolean) readonly enhanced!: boolean;
  @Prop(Boolean) readonly hidePlaceholder!: boolean;

  get nativeValue(): string {
    return this.value.toString();
  }

  get eventHandlers() {
    return {
      blur: (e: Event) => this.$emit('blur', e),
      change: this.handleChange,
      closed: (e: Event) => e.stopPropagation(),
      opened: (e: Event) => e.stopPropagation(),
    };
  }

  handleChange(e: Event) {
    if (e.target !== null) {
      this.$emit('input', (e.target as HTMLInputElement).value);
      this.$emit('change', (e.target as HTMLInputElement).value);
    }
  }

  /**
   * workaround for not being able to add options after render
   */
  get key() {
    return JSON.stringify([...this.options]);
  }

  get formattedOptions(): SelectOption[] {
    if (!isSelectOptions(this.options)) {
      return this.options.map(option => {
        return {
          key: option,
          value: option,
          label: option,
          disabled: false,
        };
      });
    }

    return this.options.map(option => {
      const out = { ...option };
      if (!out.key) {
        out.key = out.value;
      }
      if (!out.label) {
        out.label = out.value;
      }
      return out;
    });
  }

  get isTwoLine() {
    return this.enhanced && this.formattedOptions.some(opt => !!opt.description);
  }

  optionText(option: SelectOption): string {
    if (typeof option.label === 'string') {
      return option.label;
    } else {
      return option.value;
    }
  }
}
