import { Component, Vue } from 'vue-property-decorator';
import { openSnackbar as openSnack } from '@/utils/components';

@Component
export default class Form extends Vue {
  public formStatus = false;

  public checkIfFormIsValid(): boolean {
    return this.formStatus;
  }

  public setFormStatus(newStatus: boolean): void {
    this.formStatus = newStatus;
  }

  public openSnackbar(labelText: string): void {
    openSnack.call(this, labelText);
  }

  public formIsValid = false;

  public setFormInvalid(): void {
    this.formIsValid = false;
  }
  public setFormValid(): void {
    this.formIsValid = true;
  }
}
