import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import {
  DeleteTeamMember,
  Team,
  TeamMember,
  CreateTeamRequest,
  UpdateTeamRequest,
  UpdateTeamMember,
} from '@/types/teams.types';
import * as TeamsAPI from '@/api/teams.api';

@Module({ name: 'teams', store, dynamic: true })
class Teams extends VuexModule {
  public teams: Team[] = [];
  public teamsRecord: Record<number, Team> = {};
  public hasLoadedTeams = false;

  @Mutation
  public deleteTeamMember(deleteTeamMember: DeleteTeamMember): void {
    const { teamId, mudId, teamMembers } = deleteTeamMember;
    this.teamsRecord[teamId].teamMembers = teamMembers.filter((m: TeamMember) => {
      return m.mudId !== mudId;
    });
  }

  @Mutation
  public updateTeamMemberRole(updateTeamMember: UpdateTeamMember): void {
    const { roleId, roleName, mudId, teamId } = updateTeamMember;
    this.teamsRecord[teamId].teamMembers.forEach(member => {
      if (member.mudId === mudId) {
        member.roleId = roleId;
        member.roleName = roleName;
      }
    });
  }

  @Mutation
  public updateTeamMembersList(updateMembersList: { teamId: number; members: TeamMember[] }): void {
    const { teamId, members } = updateMembersList;
    this.teamsRecord[teamId].teamMembers = members;
  }

  @Mutation
  public updateTeamName(updateTeamNameObject: { teamId: number; teamName: string }): void {
    const { teamId, teamName } = updateTeamNameObject;
    this.teamsRecord[teamId].teamName = teamName;
  }

  @Mutation
  public updateTeamDescription(updateTeamDescriptionObject: {
    teamId: number;
    teamDescription: string;
  }): void {
    const { teamId, teamDescription } = updateTeamDescriptionObject;
    this.teamsRecord[teamId].teamDescription = teamDescription;
  }

  @Mutation
  public setTeams(teams: Team[]): void {
    this.teams = teams;
  }

  @Mutation
  public setTeamsRecord(teams: Team[]): void {
    teams.forEach((team: Team) => {
      this.teamsRecord[team.teamId] = team;
    });
    this.hasLoadedTeams = true;
  }

  @Action
  public async searchTeams(query: string): Promise<any[]> {
    return await TeamsAPI.searchTeams(query);
  }

  @Action
  public async getAllTeams(): Promise<void> {
    const teamsResponse = await TeamsAPI.getAllTeams();
    this.setTeams(teamsResponse.teams);
    this.setTeamsRecord(teamsResponse.teams);
  }

  @Action
  public async getAllTeamsFromDb(): Promise<void> {
    const teamsResponse = await TeamsAPI.getAllTeamsFromDb();
    this.setTeams(teamsResponse.teams);
    this.setTeamsRecord(teamsResponse.teams);
  }

  @Action({ rawError: true })
  public async deleteTeam(teamId: number): Promise<boolean> {
    return TeamsAPI.deleteTeam(teamId).then(res => res.data);
  }

  @Action
  public async createTeam(newTeam: CreateTeamRequest): Promise<number> {
    return TeamsAPI.createTeam(newTeam).then(res => res.data);
  }

  @Action({rawError:true})
  public async updateTeam(updateTeam: UpdateTeamRequest): Promise<boolean> {
    return TeamsAPI.updateTeam(updateTeam).then(res => res.data);
  }

  @Action
  public async teamById(teamId: number): Promise<any> {
    return TeamsAPI.teamById(teamId).then(res => res.data);
  }
}

export const TeamsModule = getModule(Teams);
