import { AxiosPromise } from 'axios';
import axios from '@/api/service';
import URLs from '@/api/service/urls';
import {
  CreateTeamRequest,
  ProjectTeam,
  Team,
  TeamsResponse,
  UpdateTeamRequest,
} from '@/types/teams.types';

export async function getAllTeams(): Promise<TeamsResponse> {
  const teams = await axios.get(URLs.UserTeams, {});
  return teams.data;
}

export async function getAllTeamsFromDb(): Promise<TeamsResponse> {
  const teams = await axios.get(URLs.AllTeams, {});
  return teams.data;
}

export async function searchTeams(query: string): Promise<ProjectTeam[]> {
  return (await axios.get(URLs.SearchTeams, { params: { query } })).data;
}

export function deleteTeam(teamId: number): AxiosPromise<boolean> {
  return axios.delete(URLs.DeleteTeam, {
    params: { id: teamId },
  });
}

export function createTeam(newTeam: CreateTeamRequest): AxiosPromise<number> {
  return axios.post(URLs.CreateTeam, newTeam);
}

export function updateTeam(updateTeam: UpdateTeamRequest): AxiosPromise<boolean> {
  const { teamId } = updateTeam;
  return axios.put(URLs.UpdateTeam, updateTeam, { params: { id: teamId } });
}

export function teamById(teamId: number): AxiosPromise<Team> {
  return axios.get(URLs.TeamById, { params: { id: teamId } });
}
